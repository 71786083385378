import React from "react";
import ReactDOM from "react-dom/client";
//
import "rc-slider/assets/index.css";
// STYLE
import "./styles/index.scss";
import "./index.css";
import "./fonts/line-awesome-1.3.0/css/line-awesome.css";

//import react redux
import { Provider } from "react-redux";
import { store } from "./store/store";
import Axios, { injectStore } from "./utils/axiosInterceptor";
import "./i18n/i18n";

import { GoogleOAuthProvider } from "@react-oauth/google";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";

//
import App from "./App";
import reportWebVitals from "./reportWebVitals";

Axios.initialise();
injectStore(store);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  // <React.StrictMode>
  <GoogleOAuthProvider
    // clientId="444497476375-btf04v93kb1fk9ndvk83f05u5m6pr3gg.apps.googleusercontent.com"
    clientId="721798357000-bfqsdrvou6nve1dm3mmirch5cu84d2s9.apps.googleusercontent.com"
  >
    <GoogleReCaptchaProvider reCaptchaKey="6LfjynYqAAAAAOQU74GyDlnUMaXGncBzgWmtRlqM">
      <Provider store={store}>
        <App />
      </Provider>
    </GoogleReCaptchaProvider>
  </GoogleOAuthProvider>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
