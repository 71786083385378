import React from "react";
import NavigationItem from "./NavigationItem";
import { NAVIGATION_DEMO, NAVIGATION_DEMO_DUBAI } from "data/navigation";
import config from "../../config/config";
import { useSelector } from "react-redux";

function Navigation() {
  const { menuAttractions } = useSelector((state: any) => state.masterData);

  const FINAL_MENU =
    config.region === "uae" ? NAVIGATION_DEMO_DUBAI : NAVIGATION_DEMO;

  return (
    <ul className="nc-Navigation hidden lg:flex lg:flex-wrap lg:items-center lg:space-x-1 relative">
      {FINAL_MENU.map((item) => {
        if (item.type === "megaMenu") {
          item.megaMenu = menuAttractions;
        }
        return <NavigationItem key={item.id} menuItem={item} />;
      })}
    </ul>
  );
}

export default Navigation;
