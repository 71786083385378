import axios from "axios";
import { toast } from "react-toastify";
var qs = require("qs");

const apiUserLogin = async (postData) => {
  const url = `/customer/login`;

  try {
    const res = await axios.post(url, qs.stringify(postData));
    if (res.status === 200) {
      return res.data;
    } else {
      toast.error("An error occoured. Please try again");
    }
  } catch (error) {
    console.log(error);
    toast.error("An error occoured. Please try again.");
  }
};

const apiUserSignup = async (postData) => {
  const url = `/register/customer`;
  try {
    const res = await axios.post(url, qs.stringify(postData));
    if (res.status === 200) {
      return res.data;
    } else {
      toast.error("An error occoured. Please try again");
    }
  } catch (error) {
    console.log(error);
    toast.error("An error occoured. Please try again.");
  }
};

const apiUserValidateEmailCode = async (postData) => {
  const url = `/account/confirm-account-code`;
  try {
    const res = await axios.post(url, qs.stringify(postData));
    if (res.status === 200) {
      return res.data;
    } else {
      toast.error("An error occoured. Please try again");
    }
  } catch (error) {
    console.log(error);
    toast.error("An error occoured. Please try again.");
  }
};

const apiUserForgotPassword = async (postData) => {
  const url = `/account/forgot-password`;
  // const url = `/register/check-email`;

  try {
    const res = await axios.post(url, qs.stringify(postData));
    if (res.status === 200) {
      return res.data;
    } else {
      toast.error("An error occoured. Please try again");
    }
  } catch (error) {
    console.log(error);
    toast.error("An error occoured. Please try again.");
  }
};

const apiUserResetPassword = async (postData) => {
  const url = `/account/reset-password`;
  // const url = `/register/check-email`;

  try {
    const res = await axios.post(url, qs.stringify(postData));
    if (res.status === 200) {
      return res.data;
    } else {
      toast.error("An error occoured. Please try again");
    }
  } catch (error) {
    console.log(error);
    toast.error("An error occoured. Please try again.");
  }
};

const apiUserGoogleLogin = async (postData) => {
  const url = `/account/g-login`;

  try {
    const res = await axios.post(url, qs.stringify(postData));
    if (res.status === 200) {
      return res.data;
    } else {
      toast.error("An error occoured. Please try again");
    }
  } catch (error) {
    console.log(error);
    toast.error("An error occoured. Please try again.");
  }
};
const apiUserNewsletter = async (postData) => {
  const url = `/subscribe`;

  try {
    const res = await axios.post(url, qs.stringify(postData));
    if (res.status === 200) {
      return res.data;
    } else {
      toast.error("An error occoured. Please try again");
    }
  } catch (error) {
    console.log(error);
    toast.error("An error occoured. Please try again.");
  }
};

const apiSendContactUs = async (postData) => {
  const url = `/contact-us`;

  try {
    const res = await axios.post(url, qs.stringify(postData));
    if (res.status === 200) {
      return res.data;
    } else {
      toast.error("An error occoured. Please try again");
    }
  } catch (error) {
    console.log(error);
    toast.error("An error occoured. Please try again.");
  }
};

const apiGetProfileFields = async (postData) => {
  const url = `/get-user-profile`;

  try {
    const res = await axios.post(url, JSON.stringify(postData));
    if (res.status === 200) {
      return res.data;
    } else {
      toast.error("An error occoured. Please try again");
    }
  } catch (error) {
    console.log(error);
    toast.error("An error occoured. Please try again.");
  }
};

const apiPostProfileFields = async (postData) => {
  const url = `/update-profile`;
  try {
    const res = await axios.post(url, JSON.stringify(postData));
    if (res.status === 200) {
      return res.data;
    } else {
      toast.error("An error occoured. Please try again");
    }
  } catch (error) {
    console.log(error);
    toast.error("An error occoured. Please try again.");
  }
};

const apiGetMyOrders = async (postData) => {
  const url = `/orders-history`;
  try {
    const res = await axios.post(url, JSON.stringify(postData));
    if (res.status === 200) {
      return res.data;
    } else {
      toast.error("An error occoured. Please try again");
    }
  } catch (error) {
    console.log(error);
    toast.error("An error occoured. Please try again.");
  }
};

const apiGetMyInterests = async (postData) => {
  const url = `/get-customer-interests`;
  try {
    const res = await axios.post(url, JSON.stringify(postData));
    if (res.status === 200) {
      return res.data;
    } else {
      toast.error("An error occoured. Please try again");
    }
  } catch (error) {
    console.log(error);
    toast.error("An error occoured. Please try again.");
  }
};

const apiSaveMyInterests = async (postData) => {
  const url = `/update-customer-interests`;
  try {
    const res = await axios.post(url, JSON.stringify(postData));
    
    if (res.status === 200) {
      return res.data;
    } else {
      toast.error("An error occoured. Please try again");
    }
  } catch (error) {
    console.log(error);
    toast.error("An error occoured. Please try again.");
  }
};



const apiRateOrder = async (postData) => {
  const url = `/save-user-experiences`;
  try {
    const res = await axios.post(url, JSON.stringify(postData));
    
    if (res.status === 200) {
      return res.data;
    } else {
      toast.error("An error occoured. Please try again");
    }
  } catch (error) {
    console.log(error);
    toast.error("An error occoured. Please try again.");
  }
};

const apiCheckEmailValidity = async (postData) => {
  const url = `/check-email-validity`;
  try {
    const res = await axios.post(url, JSON.stringify(postData));
    
    if (res.status === 200) {
      return res.data;
    } else {
      // toast.error("An error occoured. Please try again");
    }
  } catch (error) {
    // console.log(error);
    // toast.error("An error occoured. Please try again.");
  }
};

export {
  apiUserLogin,
  apiUserSignup,
  apiUserValidateEmailCode,
  apiUserForgotPassword,
  apiUserResetPassword,
  apiUserGoogleLogin,
  apiUserNewsletter,
  apiSendContactUs,
  apiGetProfileFields,
  apiPostProfileFields,
  apiGetMyOrders,
  apiGetMyInterests,
  apiSaveMyInterests,
  apiRateOrder,
  apiCheckEmailValidity
};
