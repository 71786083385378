// types
import { createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
// initial state
const initialState = {};

const ToastReducer = createSlice({
  name: "ToastReducer",
  initialState,
  reducers: {
    toastSuccess(state, action) {
      const { msg } = action.payload;
      toast.success(`${msg}`);
    },
    toastError(state, action) {
      const { msg } = action.payload;
      toast.error(`${msg}`);
    },
  },
});

export default ToastReducer.reducer;

export const { toastError, toastSuccess } = ToastReducer.actions;
