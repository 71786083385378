import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import translationsEN from "./en/en.json";
import translationsAR from "./ar/ar.json";
import config from "config/config";

const languageDetector = {
  type: "languageDetector",
  async: true,
  detect: async (cb) => {
    // Case 1: The user chose his preferred language setting.
    if (config.region === "saudi") {
      if (window.location.pathname.split("/")[1] === "en") {
        return cb("en");
      } else {
        return cb("ar");
      }
    } else {
      if (window.location.pathname.split("/")[1] === "ar") {
        return cb("ar");
      }
    }

    // Case 2: return the default language
    return cb("en");
  },
  init: () => {},
  cacheUserLanguage: () => {},
};
const resources = {
  en: {
    translation: translationsEN,
  },
  ar: {
    translation: translationsAR,
  },
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(languageDetector)
  .init({
    resources,
    fallbackLng: "ar", // default language
    keySeparator: false, // we do not use keys in form messages.welcome
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
