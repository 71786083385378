import { Popover, Transition } from "@headlessui/react";
import Input from "shared/Input/Input";
import React, { Fragment } from "react";
import { Link } from "react-router-dom";

const SearchDropdown = () => {
  const inputRef = React.createRef<HTMLInputElement>();

  return (
    <React.Fragment>
      <Link 
      to={'/search'}
      className={`text-2xl md:text-3xl w-12 h-12 rounded-full text-neutral-700 dark:text-neutral-300 hover:bg-neutral-100 dark:hover:bg-neutral-800 focus:outline-none flex items-center justify-center`}
    >
        <span className="sr-only">Go to search</span>
      
        <i className="las la-search"></i>
      
    </Link>
    </React.Fragment>
  );
};

export default SearchDropdown;
