import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { apiGetMetaTagsSlugs } from "apis/masterData";
import { Helmet } from "react-helmet";
import config from "config/config";

const defaultMetaTagsUae = {
  title: "Ticket Magic — Seamless Ticketing Experience",
  metaDescription:
    "Journey Beyond Expectations: Discover the Other Side of UAE through our seamless ticketing experience",
  image: "https://dubai.ticketmagic.me/Ticket_Magic.jpg",
  keyWords: "Tickets, Events, Attractions, Dubai, UAE",
};

const defaultMetaTagsSaudi = {
  title: "Ticket Magic — Seamless Ticketing Experience",
  metaDescription:
    "Journey Beyond Expectations: Discover the Other Side of Saudi Arabia through our seamless ticketing experience",
  image: "https://dubai.ticketmagic.me/Ticket_Magic.jpg",
  keyWords: "Tickets, Events, Attractions, Riyadh, Saudi Arabia",
};

const defaultMetaTagsCanada = {
  title: "Ticket Magic — Seamless Ticketing Experience",
  metaDescription:
    "Journey Beyond Expectations: Discover the Other Side of Canada through our seamless ticketing experience",
  image: "https://dubai.ticketmagic.me/Ticket_Magic.jpg",
  keyWords: "Tickets, Events, Attractions, Canada",
};

const defaultMetaTags =
  config.region === "uae"
    ? defaultMetaTagsUae
    : config.region === "saudi"
    ? defaultMetaTagsSaudi
    : defaultMetaTagsCanada;

const MetaTagsManager = () => {
  const [metaTags, setMetaTags] = useState({ ...defaultMetaTags });
  const locationPathName = useLocation().pathname;

  const formatMetaTags = (res) => {
    return {
      title: res.title ?? defaultMetaTags.title,
      metaDescription: res?.meta_description ?? defaultMetaTags.metaDescription,
      image: res?.image ?? defaultMetaTags.image,
      keyWords: res?.meta_tags ?? defaultMetaTags.keyWords,
    };
  };

  const getMetaTagsSlugs = async (pathName) => {
    const pathArr = pathName.split("/");

    const payload = { page: "general" };

    if (pathName === "/") {
      payload.slug = "home";
    } else {
      if (!pathArr[2]) {
        payload.slug = pathArr[1];
      } else {
        payload.page = pathArr[1];
        payload.slug = pathArr[2];
      }

      if (payload.page === "blog") {
        payload.slug = pathArr[3];
      }
    }

    try {
      const res = await apiGetMetaTagsSlugs(payload);
      if (res.status === "success") {
        setMetaTags(formatMetaTags(res));
      } else {
        setMetaTags({ ...defaultMetaTags });
      }
    } catch (error) {
      console.log("API Error occoured for Meta tags fetching.");
      setMetaTags(defaultMetaTagsUae);
    }
  };

  useEffect(() => {
    setTimeout(() => {
      getMetaTagsSlugs(locationPathName);
    }, 500);
  }, [locationPathName]);

  return (
    <Helmet>
      <title>{`${metaTags?.title}`}</title>

      <meta name="title" content={`${metaTags?.title}`} />
      <meta name="description" content={`${metaTags?.metaDescription}`} />

      <meta name="keyword" content={`${metaTags?.keyWords}`} />
      <meta itemprop="name" content={`${metaTags?.title}`} />
      <meta itemprop="description" content={`${metaTags?.metaDescription}`} />
      <meta itemprop="image" content={`${config.siteUrl}${metaTags?.image}`} />

      <meta property="og:type" content="article" />
      <meta property="og:title" content={`${metaTags?.title}`} />
      <meta
        property="og:description"
        content={`${metaTags?.metaDescription}`}
      />
      <meta
        property="og:image"
        content={`${config.siteUrl}${metaTags?.image}`}
      />

      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={`${metaTags?.title}`} />
      <meta
        name="twitter:description"
        content={`${metaTags?.metaDescription}`}
      />
      <meta
        name="twitter:image"
        content={`${config.siteUrl}${metaTags?.image}`}
      />
    </Helmet>
  );
};

export default MetaTagsManager;
