import { Suspense } from 'react';

import ProgressBarLoader from './ProgressBarLoader';

// ==============================|| LOADABLE - LAZY LOADING ||============================== //

const TMLazyLoader = (Component) => (props) =>
    (
        <Suspense fallback={<ProgressBarLoader />}>
            <Component {...props} />
        </Suspense>
    );

export default TMLazyLoader;