// third-party
import { combineReducers } from "redux";

// project import
import masterData from "./masterData";
import cart from "./cart";
import user from "./user";
import ToastReducer from "./toastReducer";
// ==============================|| COMBINE REDUCERS ||============================== //

const reducers = combineReducers({ masterData, cart, user, ToastReducer });

export default reducers;
