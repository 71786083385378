import { useMemo } from "react";
import { ShoppingCartIcon } from "@heroicons/react/24/outline";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

const CartIcon = ({ className = "" }) => {
  const cart = useSelector((state) => state.cart);

  const getCartTotal = useMemo(() => {
    var totaltickets = 0;
    cart.tickets.forEach((event) => {
      event.tickets.forEach((ticket) => {
        if (
          ticket?.totals > 0 &&
          (!ticket.parent_id || ticket.is_editable == "true") //ticket must have total and (ticket has not parent OR ticket is editable)
        ) {
          totaltickets += ticket?.totals;
        }
      });
    });
    return totaltickets;
  }, [cart]);

  return (
    <div className="relative inline-flex items-center">
      <Link
        to={"/cart"}
        className={`text-2xl md:text-3xl w-12 h-12 rounded-full text-neutral-700 dark:text-neutral-300 hover:bg-neutral-100 dark:hover:bg-neutral-800 focus:outline-none flex items-center justify-center ${className}`}
      >
        <span className="sr-only">Go to shopping cart</span>

        <ShoppingCartIcon className="w-7 h-7" aria-hidden="true" />
        {cart?.tickets?.length > 0 && (
          <div className="cart-badge absolute inline-flex items-center justify-center w-6 h-6 text-xs font-bold text-green bg-yellow-400 border-2 border-blue rounded-full -top-2 -right-2 dark:border-gray-900 dark:text-white">
            {getCartTotal}
          </div>
        )}
      </Link>
    </div>
  );
};

export default CartIcon;
