import React from 'react'
import './index.css'

function ProgressBarLoader() {
  return (
    <div className="TMLazyLoaderProgress">
        <div className="TMLazyLoaderBar">
        </div>
    </div>
  )
}

export default ProgressBarLoader