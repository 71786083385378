import React, { useEffect } from "react";
import Confetti from "react-confetti";
import { useDispatch, useSelector } from "react-redux";
import { setConfetti } from "../../store/reducers/masterData";

const TMConfetti = () => {
  const { confetti } = useSelector((state) => state.masterData);

  const dispatch = useDispatch();
  useEffect(() => {
    if (confetti?.time) {
      setTimeout(() => {
        dispatch(setConfetti({ confetti: { show: false, time: null } }));
      }, confetti?.time ?? 1000);
    }
  }, [confetti.show]);

  return confetti.show === true ? <Confetti recycle={false} /> : <></>;
};

export default TMConfetti;
