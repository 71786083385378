import React, { FC, ImgHTMLAttributes } from "react";
// import checkInViewIntersectionObserver from "utils/isInViewPortIntersectionObserver";
// import PlaceIcon from "./PlaceIcon";

export interface NcImageProps extends ImgHTMLAttributes<HTMLImageElement> {
  containerClassName?: string;
}

const NcImage: FC<NcImageProps> = ({
  containerClassName = "",
  alt = "nc-imgs",
  src = "",
  className = "object-cover w-full h-full",
  ...args
}) => {
  return (
    <div
      className={`nc-NcImage ${containerClassName}`}
      data-nc-id="NcImage"
      // ref={_containerRef}
    >
      <img
        src={src}
        loading="lazy"
        data-lazy={src}
        className={`${className}`}
        alt={alt}
        {...args}
      />
    </div>
  );
};

export default NcImage;
