import React from 'react'
import Logo from "shared/Logo/Logo";
function CustomMobileHeader() {
  return (
    
       <div className="relative z-10 flex flex-1 justify-center">
              <Logo />
            </div>

  )
}

export default CustomMobileHeader
